import { Dialog } from '@headlessui/react'
import classNames from 'classnames'
import React from 'react'

interface DialogProps {
  isOpen: boolean
  isLoading?: boolean
  setIsOpen: (isOpen: boolean) => void
  onClose?: () => Promise<void> | void
  onSubmit?: () => Promise<void> | void
  submitText: string
  closeText: string
  children: React.ReactNode
  reverseButtons?: boolean
}

const Modal: React.FC<DialogProps> = ({
  isOpen,
  isLoading,
  setIsOpen,
  onClose,
  onSubmit,
  submitText,
  closeText,
  children,
  reverseButtons,
}) => {
  const handleClose = async () => {
    if (onClose) {
      await onClose()
    }
    setIsOpen(false)
  }

  const handleSubmit = async () => {
    if (onSubmit) {
      await onSubmit()
    }
    setIsOpen(false)
  }
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="modal-box">
          {children}
          <div className="flex justify-center mt-5">
            <button
              type="button"
              className={classNames(
                { 'btn-primary': !reverseButtons, 'btn-error': reverseButtons },
                'btn text-white'
              )}
              onClick={handleClose}>
              {closeText}
            </button>
            <button
              disabled={isLoading}
              type="button"
              className={classNames(
                { 'btn-primary': reverseButtons, 'btn-error': !reverseButtons },
                'btn text-white ml-2'
              )}
              onClick={handleSubmit}>
              {submitText}
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default Modal
